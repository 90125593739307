<template>
  <div class="container">
    <div
      class="container__close"
      @click="$pop.down()"
    >
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L6 6M6 6L11 11M6 6L1 11M6 6L11 1" stroke="white" stroke-width="2" stroke-linecap="round"/>
      </svg>
    </div>

    <div class="container__content">
      <iframe
        :src="$i18n.locale === 'en' ? 'https://www.youtube.com/embed/O17QvzcxB1o' : 'https://www.youtube.com/embed/PI4uSvyVRy0'"
        frameborder="0" allowfullscreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  max-width: 970px;

  .container__close {
    position: absolute;
    top: -12px;
    right: -12px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    background: #202539;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;

    svg {
      margin: 0 auto;
    }
  }

  .container__content {
    position: relative;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    aspect-ratio: 16 / 9;

    @supports not (aspect-ratio: 16 / 9) {
      &::before {
        float: left;
        padding-top: 56.25%;
        content: '';
      }

      &::after {
        display: block;
        content: '';
        clear: both;
      }
    }

    iframe {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
